<template>
  <OnboardingStep
    :title="$t('onboarding.billingIntroView.title')"
    :next-text="$t('onboarding.billingIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.billingIntroView.paragraphs" />
    <RaiLink :href="billingLink" target="_blank">
      {{ $t("onboarding.billingIntroView.subscriptionLink") }}
    </RaiLink>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { RaiLink } from "@/core-ui";

export default {
  name: "BillingIntroView",
  components: { I18nArray, RaiLink },
  extends: BaseStepView,
  computed: {
    billingLink() {
      return this.$t("onboarding.billingIntroView.subscriptionLinkHref", {
        domain: process.env.VUE_APP_RAI_DOMAIN,
        storeId: this.storeId,
      });
    },
  },
};
</script>
